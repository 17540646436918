import { searchConfig } from '@cocoonspace/shared/config/search-config'
import { twTheme } from '~/theme'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useScroll } from 'framer-motion'
import { Search } from 'lucide-react'
import { Trans, useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { buttonVariants } from '~/components/ui/button'
import { Skeleton } from '~/components/ui/skeleton'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '~/components/ui/tooltip'
import { cn } from '~/lib/utils'
import { useTracking } from '~/providers/tracking-provider'
import { useUserContext } from '~/providers/user-provider'
import { Card } from '../ui/card'
import { useMediaQuery } from '@cocoonspace/shared/hooks/use-media-query.hook'

const NavbarMenu = dynamic(
	() => import('./nav-bar-menu').then((res) => res.NavbarMenu),
	{
		ssr: false,
		loading: () => (
			<>
				{Array.from({ length: 3 }).map((_, i) => (
					<Skeleton
						key={i}
						className='mr-8 h-[16px] w-[100px] max-md:hidden'
					/>
				))}
			</>
		),
	},
)

const Item = ({
	href,
	isActive,
	children,
}: PropsWithChildren<{ href: string; isActive: boolean }>) => (
	<Link
		href={href}
		className={cn(
			'flex w-[100px] flex-1 items-center justify-center gap-2 rounded border capitalize',
			'first:rounded-r-none first:border-r-0 last:rounded-l-none last:border-l-0',
			{
				'border-primary bg-primary font-bold text-primary-foreground': isActive,
			},
		)}
	>
		{children}
	</Link>
)

export const OfferSwitch = () => {
	const router = useRouter()
	const isDesktop = useMediaQuery(`(min-width: ${twTheme.theme.screens.md})`)
	const { userQuery } = useUserContext()

	const { isAuth } = useAuth()

	const getCurrentZone = () => {
		if (isAuth && userQuery.data?.default_zone) {
			return userQuery.data.default_zone
		}

		if (
			typeof router.query.zone === 'string' &&
			Object.keys(searchConfig.zones).includes(router.query.zone)
		) {
			return router.query.zone
		}

		return 'paris'
	}

	const currentZone = getCurrentZone()

	const linkQueryParams = useMemo(() => {
		if (isDesktop && router.query.isMapVisible === undefined) {
			return ''
		}

		return `?isMapVisible=${router.query.isMapVisible}`
	}, [router.query.isMapVisible, isDesktop])

	return (
		<Card
			data-testid='zone-switch'
			className='flex h-[40px] flex-row'
		>
			<Item
				href={`/${currentZone}${linkQueryParams}`}
				isActive={router.route === '/[zone]'}
			>
				Cocoons
			</Item>

			<Item
				href={`/desks/${currentZone}${linkQueryParams}`}
				isActive={router.route === '/desks/[zone]'}
			>
				Desks
			</Item>
		</Card>
	)
}

const SearchBtn = () => {
	const { t } = useTranslation()
	const { track } = useTracking()
	const { userQuery } = useUserContext()

	return (
		<Link
			data-testid='find-cocoon-button'
			className={buttonVariants({ variant: 'gradient' })}
			href={`/${userQuery.data?.default_zone || 'paris'}`}
			onClick={() => track.click('navbar-findACocoon-button')}
		>
			<Search
				size={16}
				className='mr-2'
			/>

			{t('cwNavbar:findCocoon')}
		</Link>
	)
}

const CocoonOrDeskBtn = () => {
	const { track } = useTracking()
	const { userQuery } = useUserContext()

	return (
		<TooltipProvider>
			<div className='!px-6 [&_a]:!border-white flex flex-row items-center divide-x divide-transparent'>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Link
							href={`/${userQuery.data?.default_zone || 'paris'}`}
							data-testid='find-cocoon-button'
							className={buttonVariants({
								className:
									'flex w-[150px] flex-col rounded-r-none leading-none',
							})}
							onClick={() => track.click('navbar-findACocoon-button')}
						>
							<span className='font-normal text-xs'>{'Trouver'}</span>

							{'Un Cocoon'}
						</Link>
					</TooltipTrigger>

					<TooltipContent
						className='text-center'
						sideOffset={15}
					>
						<Trans defaults='Un espace <strong>privé et cosy</strong> pour vos réunions <br/> et rendez-vous professionnels.' />
					</TooltipContent>
				</Tooltip>

				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Link
							href='/desks/paris'
							className={buttonVariants({
								className:
									'flex w-[150px] flex-col rounded-l-none leading-none',
							})}
						>
							<span className='font-normal text-xs'>{'Découvrir'}</span>
							Le Desk
						</Link>
					</TooltipTrigger>

					<TooltipContent
						className='divide-none text-center'
						sideOffset={15}
					>
						<Trans defaults='Un espace de travail <strong>partagé</strong> <br/> à la demande.' />
					</TooltipContent>
				</Tooltip>
			</div>
		</TooltipProvider>
	)
}

export const AppNavbar = () => {
	const router = useRouter()
	const { track } = useTracking()

	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const { scrollY } = useScroll()

	useEffect(() => {
		if (searchConfig.routesWithoutSearch.includes(router.route)) {
			setIsSearchVisible(false)
		} else if (router.route === '/') {
			setIsSearchVisible(false)

			const unsub = scrollY.on('change', (y) => setIsSearchVisible(y > 250))

			return () => unsub()
		} else {
			setIsSearchVisible(true)
		}
	}, [router.route])

	const showSearchActionButton = ['/[zone]', '/desks/[zone]'].includes(
		router.route,
	)

	return (
		<nav
			className={cn(
				'sticky inset-x-0 top-0 z-40 h-[60px] w-full print:hidden',
				'bg-white shadow',
				'flex shrink-0 flex-row items-center px-6',
				'transition-all delay-100',
			)}
		>
			<Link
				href='/'
				className='mr-auto flex flex-row items-start gap-2 pb-1 font-bold text-primary text-xl leading-none md:text-4xl'
				onClick={() => track.click('navbar-brand-link')}
			>
				cocoon space
				{process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && (
					<span className='pt-2 text-[10px] text-gray-700 uppercase leading-none'>
						{process.env.NEXT_PUBLIC_APP_ENV}
					</span>
				)}
			</Link>

			{isSearchVisible && (
				<div className='-translate-x-1/2 absolute left-1/2 inline-flex max-md:hidden'>
					{router.route === '/dashboard' ? <CocoonOrDeskBtn /> : <SearchBtn />}
				</div>
			)}

			{showSearchActionButton && (
				<div className='-translate-x-1/2 absolute left-1/2 inline-flex max-md:hidden'>
					<OfferSwitch />
				</div>
			)}

			<NavbarMenu />
		</nav>
	)
}
